//  (a) Cookies
// The third parties which need to act are:
//  (b) Window Urls

import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { AgeGateActions, AgeGateUIPolicy, AgeGateConfig } from '@viewlio/types/src/AgeGate'
import { NextRouter } from 'next/router'

export const ageGateActions = (
  {
    cookie,
    expiresDays,
    statesWithDynamicPricing,
  }: AgeGateConfig,
  router: NextRouter,
  ageGateUIPolicy?: AgeGateUIPolicy,
): AgeGateActions => {
  const setCookie = (cookieKey, value, expires) =>
    cookie.set(cookieKey, value, { expires })

  const getStatePricingCode = (stateCode: string) => {
    if (
      !ageGateUIPolicy.isTriggerStatePricingEnabled ||
      !statesWithDynamicPricing.includes(stateCode)
    ) {
      return null
    }

    return stateCode
  }

  return {
    handleAgreeButtonClick: (stateCode) => {
      const cookie = ageGateUIPolicy.isWarrantyAgeGate
        ? viewlioConfig.cookies.warrantyAgeGate
        : viewlioConfig.cookies.ageGate

      setCookie(cookie, true, expiresDays)
      setCookie(viewlioConfig.cookies.stateAbbr, stateCode, expiresDays)

      return getStatePricingCode(stateCode)
    },
    handleRouteToLocatorButtonClick: (stateCode) => {
      setCookie(viewlioConfig.cookies.ageGate, true, expiresDays)
      setCookie(viewlioConfig.cookies.stateAbbr, stateCode, expiresDays)
      router.push('/locator')
    },
  }
}
