import { bugsnagClient } from 'lib/bugsnag'

const RELATIVE_URL_REGEXP = new RegExp('^(?!//)(/([a-zA-Z0-9_/-]+)?)$')

const goToUrl = (path: string) => {
  window.location.assign(`${window.location.protocol}//${window.location.host}${path}`)
}

export const redirectToRelativeUrl = (pathWithMaybeQueryString: string) => {
  const {
    search: maybeQueryString,
    pathname,
  } = new URL(
    pathWithMaybeQueryString,
    // We use example.com as a second parameter here as URL is not able to
    // handle relative paths unless we specify a base path. As we don't care
    // about host at all, we just provide some base path to avoid the isssue
    'https://www.juul.com',
  )

  if (RELATIVE_URL_REGEXP.test(pathname)) {
    goToUrl(`${pathname}${maybeQueryString}`)
  } else {
    bugsnagClient?.notify?.('redirectToRelativeUrl is attempting to redirect to a non-valid relative url. Redirecting instead to home-page')
    goToUrl('/')
  }
}
