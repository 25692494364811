export const getMessages = (type: 'regular' | 'warranty') => ({
  ACCESSORY_WARRANTY_LINK_TEXT: `age_gate.components.${type}.accessory_warranty_link_text`,
  ACCESSORY_WARRANTY_LINK_URL: `age_gate.components.${type}.accessory_warranty_link_url`,
  AGE_REQUIREMENT_FULLSCREEN_TEXT: `age_gate.components.${type}.age_requirement_fullscreen.text_updated`,
  AGE_REQUIREMENT_LINK_TEXT: `age_gate.components.${type}.age_requirement.link_text`,
  AGE_REQUIREMENT_TEXT: `age_gate.components.${type}.age_requirement.text`,
  AGE_REQUIREMENT_URL: `age_gate.components.${type}.age_requirement.url`,
  AGREE_TEXT: `age_gate.components.${type}.agree_text`,
  AV_REQUIRED: `age_gate.components.${type}.av_required`,
  BLOCKED_STATE_WARNING: `age_gate.components.${type}.blocked_state_warning`,
  BLOCKED_STATE_WARNING_FULLSCREEN: `age_gate.components.${type}.blocked_state_warning_fullscreen`,
  COOKIE_POLICY: `age_gate.components.${type}.cookie-policy`,
  DEVICE_WARRANTY_LINK_TEXT: `age_gate.components.${type}.device_warranty_link_text`,
  DEVICE_WARRANTY_LINK_URL: `age_gate.components.${type}.device_warranty_link_url`,
  DISAGREE_LINK_TEXT: `age_gate.components.${type}.disagree.link_text`,
  DISAGREE_TEXT: `age_gate.components.${type}.disagree.text`,
  DISAGREE_URL: `age_gate.components.${type}.disagree.url`,
  DOB_AGE_GATE_CTA_STORE_LOCATOR: `age_gate.components.${type}.dob_age_gate.cta_text.store_locator`,
  DOB_AGE_GATE_CTA_TEXT_SUBMIT: `age_gate.components.${type}.dob_age_gate.cta_text.submit`,
  DOB_AGE_GATE_UNDERAGE_URL: `age_gate.components.${type}.dob_under_age_url`,
  DOB_CONSENT_COPY: `age_gate.components.${type}.dob_consent_copy`,
  DOB_INPUT_COPY: `age_gate.components.${type}.dob_input_copy`,
  DOB_IN_THE_FUTURE: `age_gate.components.${type}.dob_errors.date_in_the_future`,
  INTELLECTUAL_PROPERTY_WARNING_LINK_TEXT: `age_gate.components.${type}.intellectual_property_warning.link_text`,
  INTELLECTUAL_PROPERTY_WARNING_TEXT: `age_gate.components.${type}.intellectual_property_warning.text`,
  INTELLECTUAL_PROPERTY_WARNING_URL: `age_gate.components.${type}.intellectual_property_warning.url`,
  LANGUAGE_SELECTOR: `age_gate.components.${type}.language_selector`,
  MISSION_TEXT: `age_gate.components.${type}.mission_text`,
  PLACEHOLDER_DAY: 'age_gate.components.placeholders.day',
  PLACEHOLDER_MONTH: 'age_gate.components.placeholders.month',
  PLACEHOLDER_YEAR: 'age_gate.components.placeholders.year',
  POD_REPLACEMENT_LINK_TEXT: `age_gate.components.${type}.pod_replacement_link_text`,
  POD_REPLACEMENT_LINK_URL: `age_gate.components.${type}.pod_replacement_link_url`,
  RESELLER_WARNING_LINK_TEXT: `age_gate.components.${type}.reseller_warning.link_text`,
  RESELLER_WARNING_TEXT: `age_gate.components.${type}.reseller_warning.text`,
  RESELLER_WARNING_URL: `age_gate.components.${type}.reseller_warning.url`,
  ROUTE_TO_LOCATOR_TEXT: `age_gate.components.${type}.route_to_locator_text`,
  SELECT_STATE_COPY: `age_gate.components.${type}.select_state_copy`,
  SELECT_STATE_LABEL: `age_gate.components.${type}.select_state_label`,
  STATES: 'age_gate.components.states',
  STATES_DEFAULT: 'age_gate.components.states.default',
  STATE_AGE_GATE_SUBMIT_ERROR_STATE_MISSING: `age_gate.components.${type}.state_age_gate.submit_error.state_missing`,
  WARRANTY_LINKS: `age_gate.components.${type}.warranty_links`,
  WARRANTY_SUPPORT_LINK_TEXT: `age_gate.components.${type}.warranty_support.link_text`,
  WARRANTY_SUPPORT_TEXT: `age_gate.components.${type}.warranty_support.text`,
  WARRANTY_SUPPORT_URL: `age_gate.components.${type}.warranty_support.url`,
})
