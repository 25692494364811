import { paths } from '@viewlio/config/src/viewlioConfig/paths'
import { AgeGateUIPolicy, AgeGateConfig , StateListItem } from '@viewlio/types/src/AgeGate'

// ageGateUIPolicy
//
// This function component is responsible for deciding the state of the app
// It provides the following methods that assist with this
// a. getInitialGeoState:
//      For countries with the geo states dropdown visible, the app needs to
//      figure out the initial state to select in the dropdown. This is
//      to be done based on the geolocation of the user and the states list
//      received from the backend service. This method helps with the decision.
// b. isWarrantyAgeGate:
//      We currently have two different workflows for the age gate based on
//      whether a user is on a `warranty age gate enabled page` or not.
//      This method assists us with the decision.
// c. isTriggerStatePricingEnabled:
//      For the US currently, the backend service needs to know which state the
//      user resides in, for tax pricing purposes.
// d. shouldDisableAgreeButton:
//      Currently for some blocked states/provinces like quebec, we need to
//      not allow the user to verify his age (to get to the full shop site).
//      This method assists us with the above decision.
// e. shouldShowRouteToLocatorButton:
//      There are some provinces where we need to route the user to the store
//      locator url from the age gate. Since thats the only functionality that
//      the store should have. This method assists us with the decision.
// f. shouldShowBlockedStateWarning:
//      For some blocked states like quebec, we need to show a warning message
//      (required by compliance). This method assists us with this decision.

export const ageGateUIPolicy = ({
  body,
  properties,
  windowLocation,
  geolocation,
  routerQuery,
}: AgeGateConfig): AgeGateUIPolicy => {
  const { agree, blockedStateWarning, blockedStates, selector } = body

  // Below URLs are understood as warranty urls
  // We will attempt to launch the warranty age gate for these urls
  // (if warranty age gate is enabled for the store)
  const WARRANTY_URLS = /claim-warranty/

  const blockedState = (selectedState: StateListItem) =>
    blockedStates && blockedStates.includes(selectedState.value)

  const getTheGeoState = () =>
    geolocation && {
      label: geolocation.city,
      value: geolocation.stateAbbr,
    }

  return {
    blockedState,

    getInitialGeoState: () => {
      const getStateFromDropdown = () =>
        selector?.states && // Get GeoLocation State From List of States
        ((geolocation &&
          selector.states.find(e => e.value === geolocation.stateAbbr)) || {
          label: '',
          value: '',
        }) // Use placeholder if GeoLocation fails

      const emptyStateHash = ({ label: '', value: '' })

      return getStateFromDropdown() || getTheGeoState() || emptyStateHash
    },

    getTheGeoState,

    isTriggerStatePricingEnabled:
      typeof agree !== 'boolean' && agree?.triggerStatePricing,

    isWarrantyAgeGate:
      Boolean(properties) &&
      Boolean(properties.hasWarrantyAgeGate) &&
      Boolean(windowLocation.pathname.match(WARRANTY_URLS)),

    shouldDisableAgreeButton: (selectedState: StateListItem) =>
      typeof agree !== 'boolean' &&
      Boolean(agree?.disableForBlockedStates) &&
      blockedState(selectedState),

    shouldShowBlockedStateWarning: (selectedState: StateListItem) =>
      blockedStateWarning && blockedState(selectedState),

    shouldShowRouteToLocatorButton: (selectedState: StateListItem) =>
      typeof agree !== 'boolean' &&
      Boolean(agree?.routeToLocatorForBlockedStates) &&
      !(routerQuery.redirect as string || '').replace(/^(\/[a-z]{2}-[A-Z]{2}\/?)/, '/').startsWith(paths.support) &&
      blockedState(selectedState),
  }
}
